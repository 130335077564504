/**
 * @summary     DataTables OData addon
 * @description Enables jQuery DataTables plugin to read data from OData service.
 * @version     1.0.4
 * @file        jquery.dataTables.odata.js
 * @authors     Jovan & Vida Popovic
 *
 * @copyright Copyright 2014 Jovan & Vida Popovic, all rights reserved.
 *
 * This source file is free software, under either the GPL v2 license or a
 * BSD style license, available at:
 *   http://datatables.net/license_gpl2
 *   http://datatables.net/license_bsd
 * 
 * This source file is distributed in the hope that it will be useful, but 
 * WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY 
 * or FITNESS FOR A PARTICULAR PURPOSE. See the license files for details.
 * 
 */

function fnServerOData(sUrl, aoData, fnCallback, oSettings) {
  var dateFormat = 'DD-MM-YYYY';
  var oParams = {};
  $.each(aoData, function (i, value) {
    oParams[value.name] = value.value;
  });

  var data = {
    "$format": "json"
  };



  // If OData service is placed on the another domain use JSONP.
  var bJSONP = oSettings.oInit.bUseODataViaJSONP;

  if (bJSONP) {
    data.$callback = "odatatable_" + (oSettings.oFeatures.bServerSide ? oParams.sEcho : ("load_" + Math.floor((Math.random() * 1000) + 1)));
  }

  $.each(oSettings.aoColumns, function (i, value) {
    if (value.sType == 'actions' || value.sType == 'calculated' || value.sType == 'display') return
    if (value.sName == "" && value.mData == "") return
    var sFieldName = (value.sName !== null && value.sName !== "") ? value.sName : ((typeof value.mData === 'string') ? value.mData : null);
    if (sFieldName === null || !isNaN(Number(sFieldName))) {
      sFieldName = value.sTitle;
    }
    if (sFieldName === null || !isNaN(Number(sFieldName))) {
      return;
    }
    if (data.$select == null) {
      data.$select = sFieldName;
    } else {
      data.$select += "," + sFieldName.split('.')[0];
    }
  });

  if (oSettings.oFeatures.bServerSide) {

    data.$skip = oSettings._iDisplayStart;
    if (oSettings._iDisplayLength > -1) {
      data.$top = oSettings._iDisplayLength;
    }

    // OData versions prior to v4 used $inlinecount=allpages; but v4 is uses $count=true
    if (oSettings.oInit.iODataVersion !== null && oSettings.oInit.iODataVersion < 4) {
      data.$inlinecount = "allpages";
    } else {
      data.$count = true;
    }

    var asFilters = [];
    var asColumnFilters = []; //used for jquery.dataTables.columnFilter.js
    var asFiltersForm = [];

    $.each(oSettings.aoColumns,
        function (i, value) {
          if (value.sType == 'actions' || value.sType == 'calculated' || value.sType == 'display') return
          if (value.sName == "" && value.mData == "") return
          var sFieldName = value.sName || value.mData;

          var columnFilter = oParams["sSearch_" + i]; //fortunately columnFilter's _number matches the index of aoColumns

          if ((oParams.sSearch !== null && oParams.sSearch !== "" || columnFilter !== null && columnFilter !== "") && value.bSearchable) {
            switch (value.sType) {
              case 'enum':
                break;
              case 'string':
              case 'html':
                if (oParams.sSearch !== null && oParams.sSearch !== "") {
                  if (!(oParams.sSearch.indexOf('<') > -1 || oParams.sSearch.indexOf('>') > -1)) {
                    // asFilters.push("substringof('" + oParams.sSearch + "', " + sFieldName + ")");
                    // substringof does not work in v4???
                    asFilters.push("indexof(tolower(" + sFieldName.replace('.', '/') + "), '" + oParams.sSearch.toLowerCase() + "') gt -1");
                  }
                }

                if (columnFilter !== null && columnFilter !== "") {
                  asColumnFilters.push("indexof(tolower(" + sFieldName.replace('.', '/') + "), '" + columnFilter.toLowerCase() + "') gt -1");
                }

                break;
              case 'boolean':

                if (oParams.sSearch !== null && oParams.sSearch !== "" && oParams.sSearch != "-1") {
                  if (!(oParams.sSearch.indexOf('<') > -1 || oParams.sSearch.indexOf('>') > -1)) {
                    asFilters.push(sFieldName + " eq " + oParams.sSearch == '1' ? 'true' : 'false');
                  }
                }
                if (columnFilter !== null && columnFilter !== "" && columnFilter != "-1") {
                  asColumnFilters.push(sFieldName + " eq " + (columnFilter == '1' ? "true" : "false"));
                }
                break;
                //case 'date':
              case 'date':
                var dateSearch = oParams.sSearch;
                //
                if (dateSearch.length >= 8) {
                  // shall we use date range search? // dates separated with a simple space or ~ char
                  if (dateSearch.indexOf(' ') > 6 || dateSearch.indexOf('~') > 6) {
                    var separator = ' ';
                    if (dateSearch.indexOf('~') > 0) {
                      separator = '~';
                    }
                    var dates = dateSearch.split(separator);
                    if (dates.length == 2) {
                      if (moment(dates[0], dateFormat).isValid() && moment(dates[1], dateFormat).isValid()) {
                        var from = "datetime'" + moment.utc(dates[0], dateFormat).hours(0).minute(0).seconds(0).toISOString() + "'";
                        var to = "datetime'" + moment.utc(dates[1], dateFormat).hours(23).minute(59).seconds(59).toISOString() + "'";
                        asFilters.push("(" + sFieldName + " ge " + from + " and " + sFieldName + " le " + to + ")");
                      }
                    }
                  } else {
                    var operator = 'eq';
                    if (dateSearch.indexOf('<') == 0) {

                      dateSearch = dateSearch.substr(1);
                      operator = 'le';

                    } else if (dateSearch.indexOf('>') == 0) {

                      operator = 'ge';
                      dateSearch = dateSearch.substr(1);

                    } else if (dateSearch.indexOf('=') == 0) {

                      dateSearch = dateSearch.substr(1);

                    }

                    if (moment(dateSearch, dateFormat).isValid()) {
                      if (operator != 'eq') {
                        var aDate = moment.utc(dateSearch, dateFormat);
                        if (operator == 'ge') {
                          aDate = aDate.hours(0).minute(0).seconds(0).toISOString();
                        } else {
                          aDate = aDate.hours(23).minute(59).seconds(59).toISOString();
                        }
                        asFilters.push(sFieldName + " " + operator + " datetime'" + aDate + "' ");
                      } else {
                        var from = "datetime'" + moment.utc(dateSearch, dateFormat).hours(0).minute(0).seconds(0).toISOString() + "'";
                        var to = "datetime'" + moment.utc(dateSearch, dateFormat).hours(23).minute(59).seconds(59).toISOString() + "'";
                        asFilters.push("(" + sFieldName + " ge " + from + " and " + sFieldName + " le " + to + ")");

                      }
                    }
                  }
                }
                break;
              case 'numeric':
                if (oParams.sSearch.indexOf('<>') == 0 || oParams.sSearch.indexOf('!=') == 0) {

                  asFilters.push(oParams.sSearch.replace('<>', sFieldName + " ne ").replace('!=', sFieldName + " ne "));

                } else if (oParams.sSearch.indexOf('>') == 0) {
                  var filter = oParams.sSearch.indexOf('>=') == 0 ?
                                       oParams.sSearch.toLowerCase().replace('>=', sFieldName + " ge ") :
                                       oParams.sSearch.toLowerCase().replace('>', sFieldName + " ge ");
                  asFilters.push(filter);

                } else if (oParams.sSearch.indexOf('<') == 0) {
                  var filter = oParams.sSearch.indexOf('<=') == 0 ?
                                      oParams.sSearch.toLowerCase().replace('<=', sFieldName + " le ") :
                                      oParams.sSearch.toLowerCase().replace('<', sFieldName + " le ");
                  asFilters.push(filter);

                } else {

                  if (!isNaN(parseInt(oParams.sSearch)) && oParams.sSearch.indexOf('/') == -1 && oParams.sSearch.indexOf('-') == -1) {

                    asFilters.push(sFieldName + " eq " + parseInt(oParams.sSearch).toString());

                  }

                }
                break;

                //var fnFormatValue = 
                //(value.sType == 'numeric') ? 
                //    function(val) { return val; } :
                //    function(val) { 
                //            // Here is a mess. OData V2, V3, and V4 se different formats of DateTime literals.
                //            switch(oSettings.oInit.iODataVersion){
                //                    // V2 works with the following format:
                //                    // http://services.odata.org/V2/OData/OData.svc/Products?$filter=(ReleaseDate+lt+2014-04-29T09:00:00.000Z)                                                              
                //                    case 4: return (new Date(val)).toISOString(); 
                //                    // V3 works with the following format:
                //                    // http://services.odata.org/V3/OData/OData.svc/Products?$filter=(ReleaseDate+lt+datetimeoffset'2008-01-01T07:00:00')
                //                    case 3: return "datetimeoffset'" + (new Date(val)).toISOString() + "'";  
                //                    // V2 works with the following format:
                //                    // http://services.odata.org/V2/OData/OData.svc/Products?$filter=(ReleaseDate+lt+DateTime'2014-04-29T09:00:00.000Z')
                //                    case 2: return "DateTime'" + (new Date(val)).toISOString() + "'"; 
                //            }
                //    }

                //if (columnFilter !== null && columnFilter !== "" && columnFilter !== "~") {
                //    asRanges = columnFilter.split("~");
                //    if (asRanges[0] !== "") {
                //        asColumnFilters.push("(" + sFieldName + " gt " + fnFormatValue(asRanges[0]) + ")");
                //    }

                //    if (asRanges[1] !== "") {
                //        asColumnFilters.push("(" + sFieldName + " lt " + fnFormatValue(asRanges[1]) + ")");
                //    }
                //}
                //break;
              default:
            }
          }
        });

    if (asFilters.length > 0) {
      data.$filter = asFilters.join(" or ");
    }

    if (asColumnFilters.length > 0) {
      if (data.$filter !== undefined) {
        data.$filter = " ( " + data.$filter + " ) and ( " + asColumnFilters.join(" and ") + " ) ";
      } else {
        data.$filter = asColumnFilters.join(" and ");
      }
    }

    var filterForm = $.grep(aoData, function (e) { return e.name == "filterForm"; });
    if (filterForm && filterForm.length > 0 && filterForm[0].value) {
      $.each(filterForm[0].value, function (i, filter) {
        if (filter && filter.value) {
          var parsed;
          if (typeof (filter.value) !== 'object') parsed = JSON.parse(filter.value);
          if (typeof (filter.value) !== 'object' && typeof (parsed) === 'object') {
            var asFilterForm = [];
            $.each(parsed, function (i, o) {
              switch (filter.type) {
                case "string":
                  asFilterForm.push("indexof(tolower(" + filter.name + "), '" + o[filter.field].toString().toLowerCase() + "') gt -1");
                  break;
                case "enum":
                  asFilterForm.push(filter.name + " eq '" + o[filter.field] + "'");
                  break;
                case "int":
                  asFilterForm.push(filter.name + " eq " + parseInt(o[filter.field]).toString());
                  break;
                case "date":
                  if (filter.interval) {

                  } else {
                    var from = "datetime'" + moment.utc(filter.value.startDate, dateFormat).hours(23).minute(59).seconds(59).toISOString() + "'";
                    var to = "datetime'" + moment.utc(filter.value.endDate, dateFormat).hours(23).minute(59).seconds(59).toISOString() + "'";
                    asFiltersForm.push("(" + filter.name + " ge " + from + " and " + filter.name + " le " + to + ")");
                  }                  
                  break;
                case "any":
                  asFilterForm.push("(" + filter.name + "/any(x: x/" + filter.field + " eq " + o[filter.field] + "))");
              };
            });
            if (asFilterForm.length > 0) asFiltersForm.push("(" + asFilterForm.join(" or ") + ")");
          } else {
            switch (filter.type) {
              case "string":
                asFiltersForm.push("indexof(tolower(" + filter.name + "), '" + filter.value.toString().toLowerCase() + "') gt -1");
                break;
              case "enum":
                asFiltersForm.push(filter.name + " eq '" + filter.value + "'");
                break;
              case "int":
                asFiltersForm.push(filter.name + " eq " + parseInt(filter.value).toString());
                break;
              case "date":
                if (filter.value.startDate && filter.value.endDate) {
                  var from = "datetime'" + moment.utc(filter.value.startDate, dateFormat).hours(23).minute(59).seconds(59).toISOString() + "'";
                  var to = "datetime'" + moment.utc(filter.value.endDate, dateFormat).hours(23).minute(59).seconds(59).toISOString() + "'";
                  asFiltersForm.push("(" + filter.name + " ge " + from + " and " + filter.name + " le " + to + ")");
                }
                break;
              case "any":
                asFilterForm.push("(" + filter.name + "/any(x: x/" + filter.field + " eq " + filter.value + "))");
            };
          }
        }
      });
    }

    if (asFiltersForm.length > 0) {
      if (data.$filter !== undefined) {
        data.$filter = " ( " + data.$filter + " ) and ( " + asFiltersForm.join(" and ") + " ) ";
      } else {
        data.$filter = asFiltersForm.join(" and ");
      }
    }

    if (oSettings.hasOwnProperty("sODataExpand")) {
      data.$expand = oSettings.sODataExpand.replace();
    }

    var asOrderBy = [];
    for (var i = 0; i < oParams.iSortingCols; i++) {
      var orderbyparams = oParams["mDataProp_" + oParams["iSortCol_" + i]].replace('.', '/') + " " + (oParams["sSortDir_" + i] || "");
      asOrderBy.push(orderbyparams);
    }

    if (asOrderBy.length > 0) {
      data.$orderby = asOrderBy.join();
    }
  }
  $.ajax(jQuery.extend({}, oSettings.oInit.ajax, {
    "url": sUrl,
    "data": data,
    "jsonp": bJSONP,
    "dataType": bJSONP ? "jsonp" : "json",
    "jsonpCallback": data["$callback"],
    "cache": false,
    "success": function (data) {
      var oDataSource = {};
      // Probe data structures for V4, V3, and V2 versions of OData response

      oDataSource.aaData = data.Results || data.value || (data.d && data.d.results) || data.d || data;

      var iCount = (data["@odata.count"]) ? data["@odata.count"] : ((data["odata.count"]) ? data["odata.count"] : ((data.__count) ? data.__count : (data.d && data.d.__count)));

      if (iCount == null) {
        if (oDataSource.aaData.length === oSettings._iDisplayLength) {
          oDataSource.iTotalRecords = oSettings._iDisplayStart + oSettings._iDisplayLength + 1;
        } else {
          oDataSource.iTotalRecords = oSettings._iDisplayStart + oDataSource.aaData.length;
        }
      } else {
        oDataSource.iTotalRecords = iCount;
      }

      oDataSource.iTotalDisplayRecords = oDataSource.iTotalRecords;

      fnCallback(oDataSource);
    }
  }));

} // end fnServerData